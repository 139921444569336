exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-article-single-tsx": () => import("./../../../src/templates/article-single.tsx" /* webpackChunkName: "component---src-templates-article-single-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-compare-single-tsx": () => import("./../../../src/templates/compare-single.tsx" /* webpackChunkName: "component---src-templates-compare-single-tsx" */),
  "component---src-templates-compare-tsx": () => import("./../../../src/templates/compare.tsx" /* webpackChunkName: "component---src-templates-compare-tsx" */),
  "component---src-templates-contact-us-tsx": () => import("./../../../src/templates/contact-us.tsx" /* webpackChunkName: "component---src-templates-contact-us-tsx" */),
  "component---src-templates-content-tsx": () => import("./../../../src/templates/content.tsx" /* webpackChunkName: "component---src-templates-content-tsx" */),
  "component---src-templates-lists-tsx": () => import("./../../../src/templates/lists.tsx" /* webpackChunkName: "component---src-templates-lists-tsx" */),
  "component---src-templates-product-single-tsx": () => import("./../../../src/templates/product-single.tsx" /* webpackChunkName: "component---src-templates-product-single-tsx" */),
  "component---src-templates-products-tsx": () => import("./../../../src/templates/products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-reviews-tsx": () => import("./../../../src/templates/reviews.tsx" /* webpackChunkName: "component---src-templates-reviews-tsx" */),
  "component---src-templates-sitemap-tsx": () => import("./../../../src/templates/sitemap.tsx" /* webpackChunkName: "component---src-templates-sitemap-tsx" */)
}

